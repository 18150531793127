import { NavigationEnd, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { environment } from '../environments/environment';
import { ConfirmationService, MessageService, PrimeNGConfig } from 'primeng/api';
import packageInfo from '../../package.json';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { filter } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        url: environment.keycloakUrl,
        realm: environment.realm,
        clientId: environment.clientId
      },
      initOptions: {
        onLoad: 'login-required',
        checkLoginIframe: false
      }
    });
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  providers: [MessageService, ConfirmationService],
})
export class AppComponent implements OnInit {
  title = 'client_angular';
  actualYear = new Date().getFullYear();
  appVersion = packageInfo.version;

  constructor(
    private config: PrimeNGConfig,
    private keycloakService: KeycloakService,
    private confirmationService: ConfirmationService,
    private gtmService: GoogleTagManagerService,
    private router: Router,
    private translateService: TranslateService
  ) {
    this.setTranslations();
    this.gtmService.addGtmToDom();
    this.configureGtmRoutes();

  }

  private configureGtmRoutes() {
    this.router.events.forEach(event => {
      if (event instanceof NavigationEnd) {
        this.gtmService.pushTag({ event: 'page', pageName: event.urlAfterRedirects });
      }
    });
  }
  
  setTranslations() {
    this.translateService.setDefaultLang('pl');
    this.translateService.get('primeng').subscribe((res: any) => {
      this.config.setTranslation(res);
    });
  }

  ngOnInit() {
    if (this.keycloakService.isUserInRole('ekowitryna-kobize-reports-access', 'ekowitryna_kobize') === false) {
      const ref = this.confirmationService.confirm({
        icon: 'pi pi-exclamation-triangle',
        closeOnEscape: false,
        header: 'Brak dostępu',
        message: 'Nie masz uprawnień do korzystania z aplikacji. Zapraszamy do zakupienia licencji.',
        rejectVisible: false,
        dismissableMask: false,
        acceptLabel: "Więcej informacji",
        accept: () => {
          window.location.href = 'https://ekowitryna.pl/subskrypcja/';
        },

      });
    }
  }
}

function myUndefinedFunction() {
  throw new Error('Function not implemented.');
}

