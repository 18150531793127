{
  "name": "ekowitryna-kobize",
  "version": "1.2.3",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build && npm run sentry:sourcemaps && npm run sentry:sourcemaps",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "sentry:sourcemaps": "sentry-cli sourcemaps inject --org mobiletract --project ekowitryna-kobize ./dist/client_angular && sentry-cli sourcemaps upload --org mobiletract --project ekowitryna-kobize ./dist/client_angular"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.1.0",
    "@angular/cdk": "^17.2.0",
    "@angular/common": "^17.1.0",
    "@angular/compiler": "^17.1.0",
    "@angular/core": "^17.1.0",
    "@angular/forms": "^17.1.0",
    "@angular/platform-browser": "^17.1.0",
    "@angular/platform-browser-dynamic": "^17.1.0",
    "@angular/router": "^17.1.0",
    "@ngrx/component-store": "^17.1.0",
    "@ngrx/effects": "^17.1.0",
    "@ngrx/store": "^17.1.0",
    "@ngrx/store-devtools": "^17.1.0",
    "@ngx-translate/core": "^15.0.0",
    "@sentry/angular-ivy": "^7.101.1",
    "@sentry/cli": "^2.28.6",
    "angular-google-tag-manager": "^1.9.0",
    "keycloak-angular": "^15.1.0",
    "keycloak-js": "^23.0.6",
    "primeicons": "^6.0.1",
    "primeng": "^17.4.0",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.1.2",
    "@angular/cli": "^17.1.2",
    "@angular/compiler-cli": "^17.1.0",
    "@types/jasmine": "~5.1.0",
    "autoprefixer": "^10.4.17",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "postcss": "^8.4.33",
    "schematics-scss-migrate": "^2.3.17",
    "tailwindcss": "^3.4.1",
    "typescript": "~5.3.2"
  }
}
